import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";

export const EmployeeEdit = (props) => {
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.hasView == 1) ? true : false;
      propsObj.hasShow = (p.hasView == 1) ? true : false;
      propsObj.hasEdit = (p.hasEdit == 1) ? true : false;
      propsObj.hasDelete = (p.hasDelete == 1) ? true : false;
      propsObj.hasCreate = (p.hasCreate == 1) ? true : false;
      propsObj.hasExport = (p.hasExport == 1) ? true : false;
    }
  }
  return (
    <Edit title="Employee Edit" {...propsObj}>
      <SimpleForm toolbar={<FormToolbar {...props} />} variant="outlined">
      <TextInput source="code" disabled={false}  fullWidth={true}  validate={[required()]}/>
      <TextInput source="name"  fullWidth={true}  validate={[required()]}/>  
      <ReferenceInput perPage={2000} label="User" source="userId" reference="users" fullWidth={true} validate={[required()]}  sort={{ field: 'userName', order: 'ASC' }}
      
      >
              <SelectInput optionText="userName" />
        </ReferenceInput> 
        <DateInput source="doj" label="Date of Joining" fullWidth={true} validate={[required()]} />
        <TextInput source="designation" label="Designation" fullWidth={true}  />  
                    <TextInput source="pan" label="PAN" fullWidth={true}  />
                    <TextInput source="adharNo" label="Adhaar No" fullWidth={true}  /> 
                    <TextInput source="bankAcNo" label="Bank Account No" fullWidth={true}  />
                    <TextInput source="bankName" label="Bank Name" fullWidth={true} />
                    <TextInput source="ifscCode" label="IFSC Code" fullWidth={true} />
                    <TextInput source="uan" label="UAN" fullWidth={true} />
                   
                    <DateInput source="dol" label="Date of Leaving" fullWidth={true}  />
                    <NumberInput source="basicSalary" label="Basic Salary" fullWidth={true}  />
                    <NumberInput source="monthlyTax" label="Monthly Tax" fullWidth={true} /> 
                    <BooleanInput source="isAbry" label="is Abry" fullWidth={true} validate={[required()]} />
                    <BooleanInput source="isPf" label="Is Pf" fullWidth={true} validate={[required()]} />
                    <BooleanInput source="isActive" label="Is Active" fullWidth={true} validate={[required()]} />
      </SimpleForm>
    </Edit>
  );
};
