import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import {
  Edit, TabbedForm, TextInput, DateInput, ReferenceInput, SelectInput, required, BooleanInput, FormTab, useRefresh, ReferenceManyField, ReferenceField, Datagrid, NumberInput, TextField,
  DateField, ImageField, ImageInput,FormDataConsumer
} from "react-admin";
import { CreateToolbar } from "../../CreateToolbar";
import VSDeleteButton from "../../VSDeleteButton";
import { useStyles } from "../../formStyles";
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TPAConfigEdit from "./TPAConfigEdit";
import CDeleteButton from "../../common/CDeleteButton";
import TPAConfigCreate from "./TPAConfigCreate";
import InsurerConfigCreate from "./InsurerConfigCreate";
import HospitalRealDetCreate from "./HospitalRealDetCreate";
import HospitalDocumentsCreate from "./HospitalDocumentsCreate";

const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 650,
  },
}));

const DisplayFileLink = (props) => {
  let { record } = props;

  if (record.docUrl && record.docUrl.length > 0)
    return (
      <a href={record.docUrl}>View File</a>
    )
  else {
    return null
  }
}
export const userStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    justifyContent: "space-between",
  },
  item: {
    width: 100,
  },
}));

export const TPAEditButton = props => {
  const setHospitalDetId = () => {
    props.setHospitalDetEdit(props.record.id);
  }
  return (
    <Button
      color="secondary" disableElevation
      size="medium"

      onClick={setHospitalDetId}
      startIcon={<EditIcon />}>Edit</Button>
  )
};

export const HospitalEdit = (props) => {
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.hasView == 1) ? true : false;
      propsObj.hasShow = (p.hasView == 1) ? true : false;
      propsObj.hasEdit = (p.hasEdit == 1) ? true : false;
      propsObj.hasDelete = (p.hasDelete == 1) ? true : false;
      propsObj.hasCreate = (p.hasCreate == 1) ? true : false;
      propsObj.hasExport = (p.hasExport == 1) ? true : false;
    }
  }

  const classes = useStyles({});
  const [isaddTpa, setIsAddTpa] = useState(false);
  const [isaddInsurer, setIsAddInsurer] = useState(false);
  const [isaddRealisation, setIsAddRealisation] = useState(false);
  const [isEditTpa, setIsEditTpa] = useState(false);
  const [hospitalDetEditId, setHospitalDetEditId] = useState(0);
  const [isaddDocuments, setIsAddDocuments] = useState(false);
  const myclasses = myStyles({});
  const refresh = useRefresh();
  const [invoiceTypeId, setInvoiceTypeId] = useState(0); 
  const setgridRecordEdit = (id) => {

    setHospitalDetEditId(id);
    setIsEditTpa(true);
  }
  const closeEdit = () => {
    setIsAddTpa(false);
    setIsEditTpa(false);
    setIsAddDocuments(false);
    setIsAddInsurer(false);
    setIsAddRealisation(false);
    refresh();
  }


  const onCancel = () => {
    setIsAddTpa(false);
    setIsEditTpa(false);
    setIsAddDocuments(false);
    setIsAddInsurer(false);
    setIsAddRealisation(false);
  }

  const handleClose = useCallback(() => {
    setIsAddTpa(false);

    //setIsAccountServiceAdd(false);
  }, []);

  return (
    <React.Fragment>
      <Edit title="Hospital Edit" {...propsObj}>

        <TabbedForm variant="outlined" redirect="list" toolbar={<CreateToolbar {...props} />}>
          <FormTab value="DETAILS" label="Hospital Detail">
            <TextInput source="code" label="Code" validate={[required()]} formClassName={classes.one_three_input} disabled={true} initialValue={"Auto Generate"} />
            <DateInput source="dealDate" label="Ent. Date" validate={[required()]} formClassName={classes.two_three_input} />
            <DateInput source="openDate" label="Open Date" validate={[required()]} formClassName={classes.last_three_input} />

            <TextInput source="name" label="Name" validate={[required()]} formClassName={classes.one_three_input} />
            <TextInput source="phoffice" label="Phone(Off)." formClassName={classes.two_three_input} />
            <TextInput source="email" label="email" formClassName={classes.last_three_input} />

            <TextInput source="phmobile" label="Mobile" formClassName={classes.one_three_input} />
            <TextInput source="tin" label="TIN" formClassName={classes.two_three_input} />
            <TextInput source="cstNo" label="GST Number" formClassName={classes.last_three_input} />

            <TextInput source="tpt" label="tpt" formClassName={classes.one_three_input} />
            <TextInput source="licno" label="LIC No" formClassName={classes.two_three_input} />
            <TextInput source="panno" label="PAN Number" formClassName={classes.last_three_input} />

            <ReferenceInput perPage={2000} label="Handle By" source="employeeId" reference="employees" formClassName={classes.one_three_input} sort={{ field: 'name', order: 'ASC' }} >
              <SelectInput optionText="name" validate={[required()]} />
            </ReferenceInput>

            <TextInput source="state" label="State" validate={[required()]} formClassName={classes.two_three_input} />
            <ReferenceInput perPage={2000} label="Area" source="areaId" reference="areas" formClassName={classes.last_three_input} sort={{ field: 'name', order: 'ASC' }}>
              <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="city" label="City" validate={[required()]} formClassName={classes.one_three_input} />

            <TextInput source="add1" label="Address 1" validate={[required()]} formClassName={classes.two_three_input} />
            <TextInput source="add2" label="Address 2" formClassName={classes.last_three_input} />

            <TextInput source="add3" label="Address 3" formClassName={classes.one_three_input} />
            <TextInput source="pincode" label="Pincode" validate={[required()]} formClassName={classes.two_three_input} />
            <TextInput source="remarks" label="Remarks" formClassName={classes.last_three_input} />

            <BooleanInput source="isCenteralHosp" label="IGST Invoice" initialValue={false} formClassName={classes.one_three_input} />
            <BooleanInput source="isImp_IOL_Inj" label="Is IMP/IOL/INJ" initialValue={true} formClassName={classes.two_three_input} />
            <ReferenceInput label="Tax Type" perPage={5000} source="taxtTypeID" filter={{ type: 'TT' }} reference="lookups" formClassName={classes.last_three_input}>
              <SelectInput optionText="name" />
            </ReferenceInput>
            <NumberInput label="Tax Percentage" source="taxPercentage" formClassName={classes.one_three_input} />

            <BooleanInput source="isCeLic" label="Is CE License" initialValue={true} formClassName={classes.two_three_input} />
            <BooleanInput source="isFireLic" label="Is Fire Lic" initialValue={true} formClassName={classes.last_three_input} />
            <BooleanInput source="isPndt" label="Is PNDT" initialValue={true} formClassName={classes.one_three_input} />

            <BooleanInput source="isPollut" label="Is Pollution" initialValue={true} formClassName={classes.two_three_input} />
            <BooleanInput source="isBioWaste" label="Is Biomedical Waste" initialValue={true} formClassName={classes.last_three_input} />
            <BooleanInput source="isPpn" label="Is PPN" initialValue={true} formClassName={classes.one_three_input} />

            <BooleanInput source="isRohiniId" label="Is Rohini Id" initialValue={true} formClassName={classes.two_three_input} />
            <BooleanInput source="isNabh" label="isBioWaste" initialValue={true} formClassName={classes.last_three_input} />
            <BooleanInput source="isActive" label="Active" initialValue={true} formClassName={classes.one_three_input} />



            <TextInput source="cpName1" label="Contact Person 1" formClassName={classes.two_three_input} />
            <TextInput source="cpMob1" label="Contact Person 1 Mobile" formClassName={classes.last_three_input} />
            <TextInput source="cpRes1" label="Contact Person 1 ResiID" formClassName={classes.one_three_input} />

            <TextInput source="cpName2" label="Contact Person 2" formClassName={classes.two_three_input} />
            <TextInput source="cpMob2" label="Contact Person 2 Mobile" formClassName={classes.last_three_input} />
            <TextInput source="cpRes2" label="Contact Person 2 ResiID" formClassName={classes.one_three_input} />

            <TextInput source="cpName3" label="CE License No." formClassName={classes.two_three_input} />
            <TextInput source="cpMob3" label="Fire License No." formClassName={classes.last_three_input} />
            <TextInput source="cpRes3" label="ROHINI ID" formClassName={classes.one_three_input} />


            <TextInput source="cpName4" label="CE License Validity" formClassName={classes.two_three_input} />
            <TextInput source="cpMob4" label="FIRE License Validity" formClassName={classes.last_three_input} />
            <TextInput source="cpRes4" label="ROHINI ID Validity" formClassName={classes.one_three_input} />

            <TextInput source="cpName5" label="PPN Validity" formClassName={classes.two_three_input} />
            <TextInput source="cpMob5" label="Remarks 1" formClassName={classes.last_three_input} />
            <TextInput source="cpRes5" label="Remarks 2" formClassName={classes.one_three_input} />
            <NumberInput  label="Tie Up Amt"  source="tieUpAmt"   formClassName={classes.two_three_input} /> 
            <NumberInput  label="Desk Mgmt Amt"  source="deskMgmtAmt"   formClassName={classes.last_three_input} /> 
            <BooleanInput source="isInvoiceOnGrossAmt" validate={[required()]}  label="Invoice On Gross Amt" formClassName={classes.one_three_input} />
            <div>

                      </div>

            <ImageField source="url" label="Header Image" fullWidth={true} />
            <ImageInput
              source="imageurl2"
              fullWidth={true}
              formClassName={classes.last_inline_input}
              label="Image "
              accept="image/*"
              className="logourl"
            >
              <ImageField source="imageurl2" title="Icon" fullWidth={true} />
            </ImageInput>


            <ImageField source="url1" label="Footer Image" fullWidth={true} />
            <ImageInput
              source="imageurl1"
              fullWidth={true}
              formClassName={classes.last_inline_input}
              label="Image "
              accept="image/*"
              className="logourl"
            >
              <ImageField source="imageurl1" title="Icon" fullWidth={true} />
            </ImageInput>


          </FormTab>
          <FormTab label="TPA ">
            <Button variant="contained" onClick={() => { setIsAddTpa(true); }}
              color="secondary">
              Add TPA
            </Button>
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="hospital-dets"
              target="hospitalId">
              <Datagrid>
                <VSDeleteButton {...props} />
                {/* <TPAEditButton setHospitalDetEdit={setgridRecordEdit} /> */}
                <ReferenceField link={false} label="TPA" source="tpaid" reference="tpas" fullWidth={true} >
                  <TextField source="name" />
                </ReferenceField>
                <TextField fullWidth={true} source="isTieUp" label="Tie-Up" />
                <TextField fullWidth={true} source="empanelled" label="Empanelled" />
                <DateField fullWidth={true} source="agrmtFDt" label="Agrmnt. Date From" locales="fr-FR" />
                <DateField fullWidth={true} source="agrmtTDt" label="Agrmnt. Date To" locales="fr-FR" />
                <TextField fullWidth={true} source="remarks" label="Remarks" />
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
          <FormTab label="Insurer ">
            <Button variant="contained" onClick={() => { setIsAddInsurer(true); }}
              color="secondary">
              Add Insurer
            </Button>
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="hospital-ins-dets"
              target="hospitalId">
              <Datagrid>
                <VSDeleteButton {...props} />
                {/* <TPAEditButton setHospitalDetEdit={setgridRecordEdit} /> */}
                <ReferenceField link={false} label="Insurer" source="insurerid" reference="insurers" fullWidth={true} >
                  <TextField source="name" />
                </ReferenceField>
                <TextField fullWidth={true} source="isTieUp" label="Tie-Up" />
                <TextField fullWidth={true} source="empanelled" label="Empanelled" />
                <DateField fullWidth={true} source="agrmtFDt" label="Agrmnt. Date From" locales="fr-FR" />
                <DateField fullWidth={true} source="agrmtTDt" label="Agrmnt. Date To" locales="fr-FR" />
                <TextField fullWidth={true} source="remarks" label="Remarks" />
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
          <FormTab label="Realisation Detail ">
            <Button variant="contained" onClick={() => { setIsAddRealisation(true); }}
              color="secondary">
              Add Realisation Config
            </Button>
            {/* <BooleanInput source="isTatInAmt" label="By Amt" />
            <BooleanInput source="isTatInBoth" label="Tat and Amt" /> */}
             <ReferenceInput label="Invoice Type" perPage={5000} source="invoiceTypeId" initialValue={53} filter={{ type: 'INVT' }} fullWidth={true}  reference="lookups" validate={[required()]}>
                    <SelectInput optionText="name"  />
          </ReferenceInput>
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="hospital-real-dets"
              target="hospitalId">
              <Datagrid>
                <VSDeleteButton {...props} />

                <ReferenceField source="claimTypeId" reference="lookups" label="Claim Type" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <TextField fullWidth={true} source="tatindays" label="Tat In Day" />
                <TextField fullWidth={true} source="billingAmount" label="Billing Amount" />
                <TextField fullWidth={true} source="billingPercentage" label="Billing Percentage" />
                <TextField fullWidth={true} source="remarks" label="Remarks" />
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
          <FormTab label=" Documents">
            <div style={{ flex: 1 }}>
              <Button onClick={() => { setIsAddDocuments(true); }}>Add New</Button>

            </div>

            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="hospital-docs"
              perPage={20000}
              target="hospitalId">
              <Datagrid>
                <CDeleteButton {...props} />
                {/* <TextField label="ITP" source="itp" />
                <TextField label="BillNo" source="billNo" /> */}
                <DateField showTime={true} label="createdDate" source="createdDate" locales="fr-FR" />
                {/* <TextField label="Document Type" source="docType" /> */}
                <ReferenceField source="typeId" reference="lookups" label="Type" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <TextField label="Remarks" source="remarks" />
                <DisplayFileLink label="Document URL" source="docUrl" />
                {/* <UploadDocument onClick={(record) => { setIsEditDocumentRecord(record); setIsEditDocuments(true); }} label="Upload Document" /> */}
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
          <FormDataConsumer >
          {({ formData, ...rest }) =>
          {

            setInvoiceTypeId(formData.invoiceTypeId)
            // setIsTAT(formData.isTatInAmt)
            // setByBoth(formData.isTatInBoth)
          }
          // alert(formData.isTatInAmt)
          
          //   formData.id && 
          //   <ReferenceInput  perPage={2000} label="Approver" source="approverId" reference="users" variant="outlined" formClassName={classes.one_three_input} filterToQuery={(id) => ({ 'id~neq': formData.id })} sort={{ field: 'userName', order: 'ASC' }}v>
          //   <SelectInput optionText="userName" formClassName={classes.one_three_input} variant="outlined" validate={[required()]} />
          // </ReferenceInput> 
          }
        </FormDataConsumer>
        </TabbedForm>

       
      </Edit>
      <Drawer
        anchor="right"
        onClose={handleClose}
        classes={{ paper: myclasses.drawerPaper, }}
        open={isaddTpa || isaddDocuments || isaddInsurer || isaddRealisation} >
        {isaddTpa && <TPAConfigCreate
          onClose={closeEdit}
          onCancel={onCancel}
          hospitalId={props.id}
          {...props} />}
        {isaddInsurer && <InsurerConfigCreate
          onClose={closeEdit}
          onCancel={onCancel}
          hospitalId={props.id}
          {...props} />}
        {isaddRealisation && <HospitalRealDetCreate
          onClose={closeEdit}
          onCancel={onCancel}
          hospitalId={props.id}
          invoiceTypeId={invoiceTypeId}
          {...props} />}
        {isaddDocuments && <HospitalDocumentsCreate
          onClose={closeEdit}
          onCancel={onCancel}
          hospitalId={props.id}
          {...props} />}
      </Drawer>

    </React.Fragment>
  );
};
