import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput, ReferenceInput,NumberInput, BooleanInput, required, maxLength, minLength,DateInput } from 'react-admin';
import { FormToolbar } from "../../common/FormToolbar";
const initialValues = {
    pan: "",
    adharNo: '',
    bankName:"" ,
    bankAcNo: "",
    ifscCode: "",
    basicSalary:0,
    monthlyTax:0,
    dol:"",
    doj:null,
    isAbry:false,
    isPf:false,
    uan:""

}

export const EmployeeCreate = props => {
    // const defaultValues = { is_active: true, faculty_type: 'Teacher' }
    return (
        <React.Fragment>
            <Create title="Edit Employee" {...props}>
                <SimpleForm warnWhenUnsavedChanges redirect="list" toolbar={<FormToolbar {...props} />} initialValues={initialValues}
                    variant="outlined" >
                    <TextInput source="code" disabled={false}  fullWidth={true}  validate={[required()]}/>
                    <TextInput source="name" label="Employee Name" fullWidth={true} validate={[required()]} />
                    <ReferenceInput perPage={2000} label="User" source="userId" reference="users" fullWidth={true} validate={[required()]}  
                    sort={{ field: 'userName', order: 'ASC' }}
                    
                    
                    >
                        <SelectInput optionText="userName" />
                    </ReferenceInput> 

                    <DateInput source="doj" label="Date of Joining" fullWidth={true} validate={[required()]} />
                    <TextInput source="designation" label="Designation" fullWidth={true}  />  
                    <TextInput source="pan" label="PAN" fullWidth={true}  />
                    <TextInput source="adharNo" label="Adhaar No" fullWidth={true}  /> 
                    <TextInput source="bankAcNo" label="Bank Account No" fullWidth={true}  />
                    <TextInput source="bankName" label="Bank Name" fullWidth={true} />
                    <TextInput source="ifscCode" label="IFSC Code" fullWidth={true} />
                    <TextInput source="uan" label="UAN" fullWidth={true} />
                    <DateInput source="dol" label="Date of Leaving" fullWidth={true}  />
                    <NumberInput source="basicSalary" label="Basic Salary" fullWidth={true}  />
                    <NumberInput source="monthlyTax" label="Monthly Tax" fullWidth={true} />                    
                    <BooleanInput source="isAbry" label="is Abry" fullWidth={true} validate={[required()]} />
                    <BooleanInput source="isPf" label="Is Pf" fullWidth={true} validate={[required()]} />
                    <BooleanInput source="isActive" label="Is Active" fullWidth={true} validate={[required()]} />
                </SimpleForm>
            </Create>
        </React.Fragment>
    );
}